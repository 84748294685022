<template>
    <div class="CEESAR-Header">
        <div class="CEESAR-Header-Left">
            <slot name = "Left"/>
        </div>
        <div class="CEESAR-Header-Middle">
            <slot name = "Middle"/>
        </div>
        <div class="CEESAR-Header-Right">
            <slot name = "Right"/>
        </div>
    </div>
</template>

<script>
export default {
        name: "CEESAR-Header"
}
</script>

<style>
</style>